html, body {
  overflow-x: hidden;
  overflow-y: hidden;
}

/*body {
  position: relative;
}*/


:root {
  --baseWidth: 100px;
  --tip-right-img: "";
  --tip-right-img-alt: "";
}

body {
  background-color: black;
  color: white;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.preloader {
  width: 50%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pen {
  position: absolute;
  /*border: 1px solid red;*/
}

.button {
  color: white;
  border: 1px solid white;
  border-radius: 100px;
  width: var(--buttonHeight);
  height: var(--buttonHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
  }
  &.svgButton {
    svg {
      width: calc(var(--buttonHeight) * 0.5);
      height: calc(var(--buttonHeight) * 0.5);
      fill: white;
    }

    &.tipSelect {
      position: absolute;
      z-index: 10;
      border-color: transparent;
      &:before, &:after {
        -webkit-transition: all 0.3s ease-in-out;  -moz-transition: all 0.3s ease-in-out;  -ms-transition: all 0.3s ease-in-out;  -o-transition: all 0.3s ease-in-out;  transition: all 0.3s ease-in-out;
      }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 5rem;
        border: 1px solid white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translate(-50%,-50%);
        -moz-transform:translate(-50%,-50%);
        -ms-transform:translate(-50%,-50%);
        -webkit-transform:translate(-50%,-50%);
        -o-transform:translate(-50%,-50%);
        line-height: calc(var(--buttonHeight));
      }
      &:after {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150%;
        z-index: 2;
        text-transform: uppercase;
        opacity: 0;
        transform:translate(-50%,-50%);
        -moz-transform:translate(-50%,-50%);
        -ms-transform:translate(-50%,-50%);
        -webkit-transform:translate(-50%,-50%);
        -o-transform:translate(-50%,-50%);
        text-align: center;
        font-size: 0.9rem;
      }
      &#tip-brush {
          transform:translate(0,-115%);
        -moz-transform:translate(0,-115%);
        -ms-transform:translate(0,-115%);
        -webkit-transform:translate(0,-115%);
        -o-transform:translate(0,-115%);
        &:after {
          content: "Brush";
        }
      }
      &#tip-chisel {
        &:after {
          content: "Chisel";
        }
      }
      &#tip-round {
        transform:translate(0,115%);
        -moz-transform:translate(0,115%);
        -ms-transform:translate(0,115%);
        -webkit-transform:translate(0,115%);
        -o-transform:translate(0,115%);
        &:after {
          content: "Round";
        }
      }
      svg {
        transform: translate(0px, -1px);
      }
      &:hover {
        background-color: transparent;
        svg {
          opacity: 0;
        }
        &:before {
          width: 150%;
          background-color: white;
        }
        &:after {
          color: black;
          opacity: 1;
        }
      }
    }
  }

  &.active, &:hover {
    background-color: white;
    color: black;

    &.svgButton {
      svg {
        fill: black;
      }
    }
  }
}

.config-right {
  left: 58%;
  position: absolute;
  top: calc(var(--baseLine) - (var(--bodyWidth) / 2) - var(--buttonHeight) - var(--bodyWidth) * 0.05);

  &.active {
    box-shadow: 0px 0px 50px 30px #ffffff59;
  }
}

.config-left {
  left: 58%;
  position: absolute;
  top: calc(var(--baseLine) + (var(--bodyWidth) / 2) + var(--bodyWidth) * 0.05);

  &.active {
    box-shadow: 0px 0px 50px 30px #ffffff59;
  }
}

.body-container {
  &.container-home {
    transform: translate(-50%, -50%) rotate(-90deg);
    left: 50%;
    top: 50%;

    &.from-right {
      animation-delay: 0.5s;
      animation-duration: 0.6s;
      animation-name: container-transition-pickright-home;

      .tip-right {
        animation-delay: 0s;
        animation-duration: 0.6s;
        animation-name: tip-transition-pickright-home;
      }
    }

    &.from-left {
      animation-delay: 0.5s;
      animation-duration: 0.6s;
      animation-name: container-transition-pickleft-home;

      .tip-left {
        animation-delay: 0s;
        animation-duration: 0.6s;
        animation-name: tip-transition-pickleft-home;
      }
    }
  }

  &.container-pickright {
    top: calc(var(--baseLine) + 2px);
    left: 0%;
    transform: translate(-50%, -50%);
    animation-delay: 0.5s;
    animation-duration: 1s;
    animation-name: container-transition-home-pickright;

    .tip-right {
      animation-delay: 0s;
      animation-duration: 0.5s;
      animation-name: tip-transition-home-pickright;
    }
  }

  &.container-pickleft {
    top: calc(var(--baseLine) - 2px);
    left: 0%;
    transform: translate(-50%, -50%) rotate(180deg);
    animation-delay: 0.5s;
    animation-name: container-transition-home-pickleft;

    .tip-left {
      animation-delay: 0s;
      animation-name: tip-transition-home-pickleft;
    }
  }

  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.tipInfo {
  position: absolute;
  top: 50%;
  right: 0px;
  padding-right: 15px;
  text-align: center;
  width: calc(var(--buttonHeight));

  #move-up {
    margin-bottom: 15px;
  }

  #move-down {
    margin-top: 15px;
  }
}

.header {
  padding: 1em;

  #logo {
    width: 162px;
  }

  a, a:visited {
    text-decoration: none;
  }

  #logo-text {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
  }

  #claim {
    font-size: 2em;
    text-transform: uppercase;
    text-align: center;
  }
}

.footer-bar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
  height: calc(14px + var(--buttonHeight));
  text-align: center;
  .button {
    float: left;
    margin: 4px;

    &.large {
      width: calc(var(--buttonHeight) * 2);
    }
  }

  #save {
    display: inline-block;
    width: 70%;
    float: none;
    line-height: var(--buttonHeight);
  }
  .delivery-info {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 11px;
    color: #919191;
  }

  #cart {
    border: none;
  }
}

.pens {
  width: 150px;
  float: right;
}

.pens img {
  width: 100px;
}

.debug {
  position: absolute;
  color: white;
}

#sketcher-body {

}

#wheel {
  &.hide {
    -webkit-animation-name: wheelHide;
    animation-name: wheelHide;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}


.plug-tip {
  pointer-events: none;
  background-size: contain;
  animation-duration: 0.6s;
  animation-fill-mode: both;

  &.tip-left {
    background-image: var(--tip-left-img);
  }

  &.tip-right {
    background-image: var(--tip-right-img);
  }
}
.shop-tt {
  position: absolute;
  top: -20px;
  width: 100%;
}

/* Desktop */

.desktop {
    max-height: 700px;

  .tip-control {
    position: absolute;
    top:50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: row;
    &.control-left {
      right: 65%;
      padding-right: 15px;
      &.edit {
        right: 75%;
      }
      .tip-info {
        .tip-center {
          text-align: right;
        }
      }
      .tipSelect {
        left: calc(var(--buttonHeight) * 2.739);
      }
    }
    &.control-right {
      left: 65%;
      padding-left: 15px;
      &.edit {
        left: 75%;
      }
      .tip-info {
        .tip-center {
          text-align: left;
        }
      }
      .tipSelect {
        right: calc(var(--buttonHeight) * 2.739);
      }
    }
    .button {
      margin: 4px;
      font-size: calc(var(--buttonHeight) / 3);

      &.large {
        width: calc(var(--buttonHeight) * 2.5);
      }
    }
    .tip-info {
      position: relative;
      width: calc(var(--buttonHeight) * 4.2);
      .tip-center {
        font-size: calc(var(--buttonHeight) / 3.5);
        font-weight: bold;
        top: 50%;
        transform: translate(0,-50%);
        position: absolute;
        width: 100%;
        .tipType {
          display: none;
        }
      }
    }
  }

  .desktop-arrows-upper, .desktop-arrows-lower {
    position: absolute;
    top: calc(50% - var(--baseHeight) * 0.5 - var(--buttonHeight));
    left: 50%;
    svg {
      width: calc(var(--buttonHeight) * 0.3);
      height: calc(var(--buttonHeight) * 0.3);
    }
    #move-up-left,#move-down-left {
      position: absolute;
      left: calc(0px - var(--bodyWidth) / 2 + var(--buttonHeight) / 4 - var(--buttonHeight) * 7.8474);
    }
    #move-up-left,#move-up-right {
    &.visible {
        transition: all;
        transition-duration: 0.5s;
        opacity: 1;
      }
      &.hidden {
        transform:translate(0,-30vh);
        transition: all;
        transition-duration: 0.5s;
        opacity: 0;
      }
    }
    #move-down-right,#move-up-right {
      position: absolute;
      left: calc(var(--buttonHeight) * 7.8474 + var(--bodyWidth) / 2 - var(--buttonHeight) - var(--buttonHeight) / 4);
    }
    #move-down-left,#move-down-right {
      &.visible {
        transition: all;
        transition-duration: 0.5s;
        opacity: 1;
      }
      &.hidden {
        transform:translate(0,30vh);
        transition: all;
        transition-duration: 0.5s;
        opacity: 0;
      }
    }
    #move-up-left,#move-down-left,#move-down-right,#move-up-right {
      border-color: transparent;
      &:hover {
        opacity: 1;
      }
    }
  }
  .desktop-arrows-lower {
    top: calc(50% + var(--baseHeight) * 0.5);
  }

  .body-container {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.unplug-right {
      .tip-right {
        animation-delay: 0s;
        animation-duration: 0.5s;
        animation-name: desktop-tip-unplug-right;
      }
    }

    &.unplug-left {
      .tip-left {
        animation-delay: 0s;
        animation-duration: 0.5s;
        animation-name:  desktop-tip-unplug-left;
      }
    }

    &.plug-right {
      animation-delay: 0.5s;
      animation-duration: 0.3s;
      animation-name: desktop-container-plug-right;

      .tip-right {
        animation-delay: 0s;
        animation-name: desktop-tip-plug-right;
      }
    }

    &.plug-left {
      animation-delay: 0.5s;
      animation-duration: 0.3s;
      animation-name: desktop-container-plug-left;

      .tip-left {
        animation-delay: 0s;
        animation-name: desktop-tip-plug-left;
      }
    }
  }
  .footer-bar {
    #save {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      width: 150px;
      float: none;
      svg {
        width: calc(var(--buttonHeight) * 0.25);
        height: calc(var(--buttonHeight) * 0.25);
        margin-left: calc(var(--buttonHeight) * 0.15);
      }
      &.disabled {
        background-color: black;
        cursor: not-allowed;
          color: grey;
          svg {
            color: grey;
            fill: grey;
            path {
              color: grey;
              fill: grey;
            }
          }
      }
    }
  }
}

/* Mobile */

.mobile {
  .button.svgButton.tipSelect {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    -webkit-transform: translateY(0) !important;
    -o-transform: translateY(0) !important;
  }
  &.home .footer-bar {
  height: calc(38px + var(--buttonHeight));
  }
  .footer-bar #save svg {
    width: calc(var(--buttonHeight) * 0.25);
    height: calc(var(--buttonHeight) * 0.25);
    margin-left: calc(var(--buttonHeight) * 0.15);
  }
  .tipColorName {
    font-size: 10px;
  }
}

/* Keyframe Animationen */


@keyframes container-transition-home-pickright {
  0% {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  100% {
    left: var(--bodyLeft);
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes container-transition-pickright-home {
  100% {
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  20% {
    left: var(--bodyLeft);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  10% {
    left: var(--bodyLeft);
    transform: translate(calc(-50% - 15px), -50%) rotate(-0deg);
  }
  0% {
    left: var(--bodyLeft);
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes desktop-container-plug-right {
  100% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(calc(-50% - 15px), -50%);
  }
  0% {
    transform: translate(-50%, -50%);
  }
}

@keyframes desktop-container-plug-left {
  100% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(calc(-50% + 15px), -50%);
  }
  0% {
    transform: translate(-50%, -50%);
  }
}

@keyframes container-transition-home-pickleft {
  0% {
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  100% {
    left: var(--bodyLeft);
    transform: translate(-50%, -50%) rotate(-180deg);
  }
}

@keyframes container-transition-pickleft-home {
  100% {
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-180deg);
  }
  20% {
    left: var(--bodyLeft);
    transform: translate(-50%, -50%) rotate(-180deg);
  }
  10% {
    left: var(--bodyLeft);
    transform: translate(calc(-50% - 15px), -50%) rotate(-180deg);
  }
  0% {
    left: var(--bodyLeft);
    transform: translate(-50%, -50%) rotate(-180deg);
  }
}

@keyframes tip-transition-home-pickright {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(0px - var(--baseWidth) + 10px), 0);
  }
}

@keyframes tip-transition-home-pickleft {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(var(--baseWidth) - 10px), 0);;
  }
}

@keyframes tip-transition-pickleft-home {
  0% {
    transform: translate(calc(var(--baseWidth) - 10px), 0);
    background-image: var(--tip-left-img);
  }
  69% {
    background-image: var(--tip-left-img);
  }
  70% {
    background-image: var(--tip-left-img-alt);
  }
  84% {
    background-image: var(--tip-left-img-alt);
  }
  85% {
    background-image: var(--tip-left-img);
  }
  100% {
    background-image: var(--tip-left-img);
    transform: translate(0, 0);
  }
}

@keyframes tip-transition-pickright-home {
  0% {
    transform: translate(calc(0px - var(--baseWidth) + 10px), 0);
    background-image: var(--tip-right-img);
  }
  79% {
    background-image: var(--tip-right-img);
  }
  80% {
    background-image: var(--tip-right-img-alt);
  }
  84% {
    background-image: var(--tip-right-img-alt);
  }
  85% {
    background-image: var(--tip-right-img);
  }
  100% {
    background-image: var(--tip-right-img);
    transform: translate(0, 0);
  }

}

@keyframes desktop-tip-plug-left {
  0% {
    transform: translate(calc(var(--baseWidth) + 10px), 0);
    background-image: var(--tip-left-img);
  }
  79% {
    background-image: var(--tip-left-img);
  }
  80% {
    background-image: var(--tip-left-img-alt);
  }
  84% {
    background-image: var(--tip-left-img-alt);
  }
  85% {
    background-image: var(--tip-left-img);
  }
  100% {
    background-image: var(--tip-left-img);
    transform: translate(0, 0);
  }

}


@keyframes desktop-tip-plug-right {
  0% {
    transform: translate(calc(0px - var(--baseWidth) - 10px), 0);
    background-image: var(--tip-right-img);
  }
  69% {
    background-image: var(--tip-right-img);
  }
  70% {
    background-image: var(--tip-right-img-alt);
  }
  84% {
    background-image: var(--tip-right-img-alt);
  }
  85% {
    background-image: var(--tip-right-img);
  }
  100% {
    background-image: var(--tip-right-img);
    transform: translate(0, 0);
  }
}

@keyframes desktop-tip-unplug-right {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(0px - var(--baseWidth) + 10px), 0);
  }
}


@keyframes desktop-tip-unplug-left {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(var(--baseWidth) - 10px), 0);

  }
}

@keyframes wheelHide {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(200px, 0);
  }
}